import React from "react";

import Navbar from "./Inicio/navbar";
import Sobre from "./Inicio/sobre";
import Itens from "./Inicio/itens";
import Avaliacao from "./Inicio/avaliacao";
import Map from "./Inicio/map";
import Footer from "./Inicio/footer";
function Site() {
    return ( <div>
        <Navbar />
        <Sobre />
        <Itens />
        <Avaliacao />
        <Map />
        <Footer />
        </div>
    )
}

export default Site;