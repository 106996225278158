import React from "react";
import { Link } from "react-router-dom";
import './itens.css';


function Itens() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <section id="Itens">
            <div>
                <h1 className="text-center">Imóveis Disponíveis</h1>
            </div>
            <div className="container text-white">
                <div className="row imoveis py-5">
                    <div className="item-imovel col-md-3 col-lg-3 col-xs-12">
                        <div className="img-imovel1">
                        </div>
                        <h4 className="pb-4 pt-3">Avaliação Imobiliária</h4>
                        <p>Avaliamos, encontramos e negociamos imóveis.</p>
                        <Link to='https://wa.link/oydjrn' onClick={scrollToTop} className="btn btn-veja btn-primary">Veja Mais</Link>
                    </div>

                    <div className="item-imovel col-md-3 col-lg-3 col-xs-12" >
                        <div className="img-imovel2">
                        </div>
                        <h4 className="pb-4 pt-3"> Corretagem de Seguros</h4>
                        <p>Protegemos, comparamos e cuidamos dos seguros.</p>
                        <Link to='https://wa.link/oydjrn' onClick={scrollToTop} className="btn btn-veja btn-primary">Veja Mais</Link>
                    </div>
                    <div className="item-imovel col-md-3 col-lg-3 col-xs-12">
                        <div className="img-imovel3">
                        </div>
                        <h4 className="pb-4 pt-3">Consultoria Financeira</h4>
                        <p>Investimos, gerenciamos e educamos financeiramente.</p>
                        <Link to='https://wa.link/oydjrn' onClick={scrollToTop} className="btn btn-veja btn-primary">Veja Mais</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Itens;