import React from "react";
import './sobre.css';

function Sobre() {

    return (
        <section id="sobre" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-xs-12 valores">
                        <div className="services-item text-center">
                            <div className="icon">
                                <i className="fa fa-bullseye"></i>
                            </div>
                            <h4>Missão</h4>
                            <p>Facilitar a realização dos sonhos imobiliários dos clientes, oferecendo serviços de corretagem confiáveis, personalizados e eficientes.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12 valores">
                        <div className="services-item text-center">
                            <div className="icon">
                                <i className="fa fa-eye"></i>
                            </div>
                            <h4>Visão</h4>
                            <p>Ser reconhecida como a principal parceira dos clientes na realização de seus sonhos e objetivos imobiliários, destacando-se pela excelência no atendimento, transparência, inovação e compromisso com a satisfação dos clientes.</p>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-xs-12 valores">
                        <div className="services-item text-center">
                            <div className="icon">
                                <i className="fa fa-shield"></i>
                            </div>
                            <h4>Valores</h4>
                            <p>Excelência: Buscar constantemente a excelência em todos os aspectos do negócio, desde a qualidade dos serviços até a eficiência operacional.</p> <br />
                        </div>
                    </div>
                </div>
                <div className="business-plan">
                    <div className="row ">
                        <div className="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
                            <div className="business-item-img">
                                <img src="../../../img/logo.png" className="img-fluid" />
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-12 pl-4">
                            <div className="business-item-info">
                                <h3 className="text-center">
                                Imóveis Carlos Cruz: Sua Parceira de Confiança para o Mercado Imobiliário
                                </h3>
                                <p className=" text-justify">A Imóveis Carlos Cruz é uma empresa estabelecida em Guarulhos, SP, dedicada ao mercado imobiliário. Nossa equipe está comprometida em oferecer serviços completos para atender às diversas necessidades dos clientes na região.
<br /><br />
Com uma equipe experiente e comprometida, buscamos proporcionar as melhores soluções em compra, venda e locação de imóveis residenciais e comerciais. Nosso foco está em oferecer um atendimento personalizado e transparente, visando sempre a satisfação e confiança de nossos clientes em cada negociação.
<br /><br />
Conhecemos profundamente o mercado local de Guarulhos e arredores, o que nos permite ajudar nossos clientes a encontrar o imóvel ideal ou realizar excelentes investimentos imobiliários.
<br /><br />
Na Imóveis Carlos Cruz, nossa missão é tornar o processo de negociação imobiliária simples, seguro e eficiente, proporcionando tranquilidade aos nossos clientes em todas as etapas. Estamos aqui para ajudá-lo a realizar seus objetivos imobiliários com confiança e sucesso.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sobre;