import React from "react";
import './avaliacao.css';

function Avaliacao() {
    return (
        <section id="avaliacao" class="section">

            <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="avaliacao-text section-header text-center">
                            <div>
                                <h2 class="section-title">Avaliações</h2>
                                <div class="desc-text">
                                    <p>Verifique aqui algumas avaliações de nossos clientes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-lg-4 col-md-6 col-xs-12 avaliacao-item">

                        <div class="avaliacao-item-wrapper">
                            <div class="avaliacao-item-text">
                                <div className="avaliacao-title">
                                    <h3><a >Estou muito satisfeito!</a></h3></div>
                                <div className="barra-p">
                                    <p>Estou extremamente satisfeito com o serviço da corretora! A equipe foi incrivelmente atenciosa e prestativa em cada etapa do processo de investimento. Eles me guiaram com profissionalismo e transparência, e graças às suas recomendações, obtive retornos muito melhores do que esperava. Recomendo fortemente a todos que desejam investir com confiança e sucesso.</p>
                                </div>
                            </div>
                            <div class="author">
                                <span class="name"><i class="lni-user"></i><a >Ana Silva</a></span>
                                <span class="date float-right"><i class="lni-calendar"></i><a >10 de
                                    Abril</a></span>
                            </div>
                        </div>

                    </div>


                    <div class="col-lg-4 col-md-6 col-xs-12 avaliacao-item">

                        <div class="avaliacao-item-wrapper">

                            <div class="avaliacao-item-text">
                                <div className="avaliacao-title">
                                    <h3><a>Superou minhas expectativas!</a></h3>
                                </div>
                                <div className="barra-p">
                                    <p>Não poderia estar mais feliz com minha escolha de corretora! Desde o primeiro contato, fui tratado com respeito e dedicação. Eles não apenas entenderam minhas necessidades de investimento, mas também me proporcionaram uma experiência personalizada, adaptada às minhas metas financeiras. Com sua orientação especializada, consegui alcançar resultados além das minhas expectativas. Definitivamente continuarei confiando neles para minhas futuras decisões de investimento.</p>
                                </div>

                            </div>
                            <div class="author">
                                <span class="name"><i class="lni-user"></i><a >Lucas Oliveira</a></span>
                                <span class="date float-right"><i class="lni-calendar"></i><a >22 de
                                    Fevereiro</a></span>
                            </div>
                        </div>

                    </div>


                    <div class="col-lg-4 col-md-6 col-xs-12 avaliacao-item">

                        <div class="avaliacao-item-wrapper">

                            <div class="avaliacao-item-text"><div className="avaliacao-title">
                                <h3><a>Simplesmente excepcional!</a></h3></div>
                                <div className="barra-p">
                                    <p>Excelente serviço e resultados impressionantes! A equipe da corretora demonstrou um profundo conhecimento do mercado e um compromisso genuíno com o sucesso de seus clientes. Eles me ajudaram a diversificar meu portfólio de maneira inteligente e a tomar decisões de investimento informadas. Além disso, sua abordagem proativa e comunicação clara me deram confiança durante todo o processo. Estou muito grato pelos ganhos que obtive e não hesitarei em recomendar esta corretora a amigos e familiares.</p>
                                </div>
                            </div>
                            <div class="author">
                                <span class="name"><i class="lni-user"></i><a >Marina Santos</a></span>
                                <span class="date float-right"><i class="lni-calendar"></i><a >07 de Janeiro</a></span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    );
}

export default Avaliacao;