import React from "react";
import './map.css';

function Map() {
    return (
        <div class="container-map" id="localizacao">
            <h1 class="text-center p-1" data-aos="fade-up" data-aos-delay="100">Localização</h1>
            <div class="container row itens-map">
                <div class="map-container col-lg-6 col-md-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.76701316324994!2d-46.53880324651786!3d-23.45064360938199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef5171d3f369b%3A0x6d4e019250a559aa!2sR.%20Segundo-Tenente%20Alu%C3%ADsio%20Farias%2C%20136%20-%20Jardim%20Santa%20Mena%2C%20Guarulhos%20-%20SP%2C%2007096-090!5e0!3m2!1spt-BR!2sbr!4v1717778868818!5m2!1spt-BR!2sbr" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="info-container text-center col-lg-6 col-md-12" data-aos="fade-up" data-aos-delay="100">
                    <h3 class="pb-2"><b>Endereço</b></h3>
                    <p class="pb-2">R. Segundo-Tenente Aluísio Farias, 136 - Jardim Santa Mena <br /> Guarulhos - SP, 07096-090</p>
                    <h3 class="pb-2"><b>Confira nossos horários</b></h3>
                    <p>Segunda a Sábado: 08h00 - 18h00</p>
                </div>
            </div>
        </div>
    );
}

export default Map;