import React from "react";
import './footer.css';

function Footer() {

    return (
        <footer class="site-footer" id="contato">
            <div class="">
                <div class="row">

                    <div class="col-lg-5 mx-lg-auto col-md-8 col-10">
                        <h1 class="text-white" data-aos="fade-up" data-aos-delay="100">Entre em
                            <strong> contato</strong> conosco.
                        </h1>
                        <ul class="social-icon">
                            <li><a href="https://wa.link/oydjrn" class="fa-brands fa-whatsapp"></a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 col-12" data-aos="fade-up" data-aos-delay="200">
                        <h3 class="my-4">Contatos</h3>
                        <p class="mb-1">
                            <i class="fa fa-phone mr-2 footer-icon"></i>
                            (11) 98675-3621
                        </p><br />
                        <p class="mb-1">
                            <i class="fa fa-phone mr-2 footer-icon"></i>
                            (11) 98684-7292
                        </p>
                        <p>
                            <a class="text-primary" href="mailto:cruzcarlosimoveis@gmail.com">
                                <i class="fa fa-envelope mr-2 footer-icon"></i>
                                cruzcarlosimoveis@gmail.com
                            </a>
                        </p>
                    </div>

                    <div class="col-lg-3 col-md-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <h3 class="my-4">Nosso estúdio</h3>

                        <p class="mb-1">
                            <i class="fa fa-home mr-2 footer-icon"></i>
                            R. Segundo-Tenente Aluísio Farias, 136 - Jardim Santa Mena, Guarulhos - SP, 07096-090
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 mx-lg-auto text-center col-md-8 col-12" data-aos="fade-up"
                    data-aos-delay="400">
                    <p class="copyright-text">Desenvolvido por &copy; G M Contact Center
                        <br />
                        <a href="https://api.whatsapp.com/send?phone=551139392301&text=Ol%C3%A1!%20Estava%20visitando%20um%20de%20seus%20sites%20e%20gostaria%20de%20saber%20mais%20sobre%20os%20seus%20servi%C3%A7os!!">(11)
                            3939-2301</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;